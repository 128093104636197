<template>
  <div class="content-wrap">
    <!------------------------------ 左部分区域 start ------------------------------>
    <div class="content-left">
      <!-- 三品一标数量 -->
      <div class="content-item products-quantity">
        <div class="content-title">
          <span>三品一标数量</span>
        </div>
        <div
          class="content-charts"
          v-loading="!products.list.length"
          element-loading-background="transparent"
        >
          <div
            id="products-quantity-chart"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </div>
      <!-- 湛渔产品 -->
      <div
        class="content-item fish-products"
        v-loading="!certificatePicture.length && !productImgs.length"
        element-loading-background="transparent"
      >
        <div class="content-title">
          <span>湛渔产品</span>
          <i @click="creatQrCode">点击查看溯源</i>
        </div>
        <div class="content-charts">
          <swiper class="swiper" :options="swiperOption">
            <swiper-slide v-for="(ele, idx) in productImgs" :key="idx">
              <div class="item-wrap">
                <img :src="ele.url" alt />
                <span>{{ ele.name }}</span>
              </div>
            </swiper-slide>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
          <swiper
            style="margin-top: 0.4rem"
            :class="[
              'swiper',
              { 'three-data': certificatePicture.length <= 3 },
            ]"
            :options="swiperOption"
          >
            <swiper-slide v-for="(ele, idx) in certificatePicture" :key="idx">
              <div class="item-wrap company-item">
                <img :src="ele.url" alt />
                <span>{{ ele.name }}</span>
              </div>
            </swiper-slide>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>
    </div>
    <!------------------------------ 左部分区域 end ------------------------------>

    <!------------------------------ 右部分区域 start ------------------------------>
    <div class="content-right">
      <!-- 园区宣传片 -->
      <div class="content-item publicity-video">
        <div class="content-title">
          <span>园区宣传片</span>
        </div>
        <div class="content-charts">
          <div class="video-box">
            <video
              v-if="infoData.videoUrl || true"
              style="min-height: 100%; min-width: 100%; object-fit: fill"
              :src="infoData.videoUrl || '//agri-cloud.oss-cn-shenzhen.aliyuncs.com/zhanyu/zyuBrand-video-new.mp4'"
              autoplay
              controls
              muted
            ></video>
            <img
              v-else
              src="../../../assets/img/large-screen/img-yu-wang.png"
              alt
            />
          </div>
        </div>
      </div>
      <!-- 园区新闻 -->
      <div class="content-item park-news">
        <div class="content-title">
          <span>园区新闻</span>
        </div>
        <div class="content-charts">
          <div class="ls-wrap" @click="dialogFormVisible = true">
            <div
              v-for="(ele, idx) in articleTop3"
              :key="idx"
              class="ls-item"
              @click="actIdx = idx"
            >
              <div class="img">
                <img :src="ele.url" alt />
              </div>
              <div class="txt-wrap">
                <p class="line-clamp_2">{{ ele.title }}</p>
                <span>{{ ele.updateTime }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!------------------------------ 右部分区域 end ------------------------------>
    <el-dialog
      :title="articleTop3[actIdx] && fmtTitle(articleTop3[actIdx].title)"
      width="882px"
      top="14vh"
      :visible.sync="dialogFormVisible"
    >
      <div class="module">
        <div
          class="module-content dialog-content"
          v-html="articleTop3[actIdx] && articleTop3[actIdx].content"
        ></div>
      </div>
    </el-dialog>
    <el-dialog
      class="qrcode-dialog"
      title="湛渔区块链溯源证书"
      :width="app.fontHandle('530', 'px')"
      top="24vh"
      :visible.sync="qrcodeVisible"
    >
      <div class="module">
        <div class="qrcode" ref="qrCodeUrl"></div>
        <div class="logo-box">
          <img
            class="logo"
            v-if="showQrcode && qrcodeVisible"
            src="../../../assets/icon/favicon.png"
            alt=""
          />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Vue from 'vue'
import { Dialog } from 'element-ui'
Vue.use(Dialog)

import QRCode from 'qrcodejs2'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css'
// import videoDemo from '../../utils/videoDemo/EZUIKitJs.vue'

import * as $echarts from 'echarts'
export default {
  name: 'zyu-brand',
  components: {
    swiper,
    swiperSlide,
  },
  inject: ['app'],
  data() {
    return {
      showQrcode: false,
      traceId: '',
      qrcodeVisible: false,
      infoData: {},
      dialogFormVisible: false,
      actIdx: 0,
      swiperOption: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        loop: false,
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 280,
          modifier: 1,
          slideShadows: true,
        },
        autoplay: {
          disableOnInteraction: false,
          delay: 2000, //1秒切换一次
        },
        // centeredSlides: true,
        // slidesPerView: 3,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      // 三品一标数量
      products: {
        idName: 'products-quantity-chart',
        list: [],
      },
      // 湛渔产品
      productImgs: [],
      certificatePicture: [],
      // 地图详情数据
      areaInfo: {},

      // 前三文章
      articleTop3: '',
      qrcode: null,
    }
  },
  computed: {},
  methods: {
    fmtTitle(txt) {
      if (txt.length > 36) {
        return txt.slice(0, 36) + '...'
      }
      return txt
    },
    async creatQrCode() {
      await this.getTraceID()
      this.qrcodeVisible = true
      setTimeout(() => {
        const url =
          'https://zhanyu-admin.0759zjsc.com/#/traceForm?traceId=' + this.traceId

        if (this.qrcode) {
          qrcode.makeCode(url) // make another code.

          return
        }
        var qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: url, // 需要转换为二维码的内容
          width: 100,
          height: 100,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H,
        })
        this.qrcode = qrcode
      }, 800)
    },
    // 玫瑰图
    productsQuantityChart() {
      let fontHandle = this.app.fontHandle
      let chart = $echarts.init(document.getElementById(this.products.idName))
      let list = this.products.list
      list.forEach(function (item) {
        item.value = +item.value
      })
      let color1 = [
        '#5442FF',
        '#EBA447',
        '#4898FB',
        '#0CCCCE',
        '#5DE9C4',
        '#E654EB',
      ]
      let option = {
        color: color1,
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c}个 ({d}%)',
        },
        polar: {},
        angleAxis: {
          interval: 1,
          type: 'category',
          data: [],
          z: 10,
          axisLine: {
            show: false,
            lineStyle: {
              color: '#0B4A6B',
              width: 1,
              type: 'solid',
            },
          },
          axisLabel: {
            interval: 0,
            show: true,
            color: '#0B4A6B',
            margin: fontHandle(8),
            fontSize: fontHandle(16),
          },
        },
        radiusAxis: {
          show: false,
        },
        calculable: true,
        series: [
          {
            stack: 'a',
            type: 'pie',
            radius: ['10%', '90%'],
            roseType: 'area',
            zlevel: 10,
            label: {
              normal: {
                show: true,
                formatter: '\n{font|{b}}\n{font2|{c}}',
                rich: {
                  font: {
                    fontSize: fontHandle(14),
                    color: '#D1E2F4',
                  },
                  font2: {
                    fontSize: fontHandle(28),
                    padding: [5, 0],
                    color: '#2AEDFF',
                    fontWeight: 'bold',
                  },
                },
                borderRadius: fontHandle(20),
                position: 'outside',
              },
              emphasis: {
                show: true,
              },
            },
            itemStyle: {},
            labelLine: {
              normal: {
                show: true,
              },
              emphasis: {
                show: false,
              },
            },
            data: list,
          },
        ],
      }
      chart.setOption(option)
    },
    getTraceID() {
      return this.$axios({
        url: '/zhanjiang-traceability/salesEntry/randomGetId',
        transformRequest: [
          function (data, headers) {
            // 对发送的 data 进行任意转换处理
            // console.log(data, headers)
            // delete headers.Authorization
            return data
          },
        ],
      }).then((res) => {
        this.traceId = res.data
      })
    },
  },
  beforeMount() {
    // 模板编译/挂载之前
  },
  mounted() {
    // 模板编译/挂载之后
    this.getTraceID()
    this.$axios('/zhanjiang-dataview/viewZhanFishBrand/queryInfo').then(
      (res) => {
        this.products.list = res.data.pieChartList
        this.infoData = res.data
        this.productsQuantityChart()
      }
    )
    this.$axios('/zhanjiang-dataview/viewZhanFishBrand/queryArticleTop3')
      .then((res) => {
        this.articleTop3 = res.data
      })
      .catch(() => {})
    this.$axios(
      '/zhanjiang-dataview/viewZhanFishBrand/queryCertificatePictureList'
    )
      .then((res) => {
        this.certificatePicture = res.data || []
      })
      .catch(() => {})
    this.$axios(
      '/zhanjiang-dataview/viewZhanFishBrand/queryProductionPictureList'
    )
      .then((res) => {
        this.productImgs = res.data
      })
      .catch(() => {})
  },
  created() {},
  beforeUpdate() {
    // 组件更新之前
  },
  updated() {
    // 组件更新之后
  },
  beforeDestroy() {
    // 组件销毁前调用
    // Tips：你确认删除XX吗？
  },
  destroyed() {
    // 组件销毁后调用
    // Tips：当前组件已被删除，清空相关内容
  },
  // 数据监听
  watch: {
    // keyName: {
    // 注意：当观察的数据为对象或数组时，curVal和oldVal是相等的，因为这两个形参指向的是同一个数据对象
    // deep: true,
    //handler (curVal, oldVal) {
    // console.log(curVal, oldVal)
    //}
    // }
    qrcodeVisible: {
      handler(cur) {
        if (cur && !this.qrcode) {
          setTimeout(() => {
            this.showQrcode = true
          }, 1400)
          return
        }
        this.showQrcode = cur
      },
    },
  },
}
</script>
<style lang="less" scoped>
/deep/ .qrcode-dialog {
  text-align: center;
  .logo-box {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0.5rem;
    height: 0.5rem;
    margin-top: -0.15rem;
    padding: 0.02rem;
    border-radius: 0.05rem;
    background: #ffffff;
    transform: translate(-50%, -50%);
    .logo {
      width: 100%;
      height: 100%;
    }
  }
  .qrcode {
    position: relative;
    display: inline-block;
    img {
      box-sizing: border-box;
      width: 4.6rem;
      height: 4.6rem;
      padding: 0.06rem; // 利用padding的特性，挤出白边  background-color: #ffffff; //设置白色背景色
    }
  }
}

@import './zyu-brand.less';

</style>
